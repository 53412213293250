import { render, staticRenderFns } from "./AssociateUser.vue?vue&type=template&id=647c2063&scoped=true&"
import script from "./AssociateUser.vue?vue&type=script&lang=js&"
export * from "./AssociateUser.vue?vue&type=script&lang=js&"
import style0 from "./AssociateUser.vue?vue&type=style&index=0&id=647c2063&prod&lane=scss&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647c2063",
  null
  
)

export default component.exports